import React from 'react';

const GoogleMap = () => {
  return (
    <iframe
      title="Google Map showing the location of Sohan Industries"
      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d39838.4618630326!2d74.327126796992!3d16.619221940343163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSOHAN%20INDUSTRIES%20Plot%20No.G-40%2C%205%20STAR%20M.I.D.C.%20Kagal%2C%20Kolhapur%20Maharashtra%20416216%20.!5e0!3m2!1sen!2sin!4v1721216620521!5m2!1sen!2sin"
      style={{ border: 0, width: "100%", height: 220 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

export default GoogleMap;
