import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Loader from './components/Loader/Loader';

// Utility function to add a delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Modified lazy import with delay
const lazyWithDelay = (importFunc, delayMs = 1000) => {
  return lazy(() =>
    Promise.all([
      importFunc(),
      delay(delayMs),
    ]).then(([moduleExports]) => moduleExports)
  );
};

const Home = lazyWithDelay(() => import('./Pages/Home/Home'));
const About = lazyWithDelay(() => import('./Pages/About/About'));
const Infrastructure = lazyWithDelay(() => import('./Pages/Infrastructure/Infrastructure'));
const Career = lazyWithDelay(() => import('./Pages/Career/Career'));
const Products = lazyWithDelay(() => import('./Pages/Products/Products'));
const Contact = lazyWithDelay(() => import('./Pages/ContactUs/Contact'));

function App() {
  return (
    <div className="App">    
      <Router>
        <div>
        <Suspense fallback={<Loader />}>
          <Header />
          
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/infrastructure" element={<Infrastructure />} />
              <Route path="/career" element={<Career />} />
              <Route path="/products" element={<Products />} />
              <Route path="/contact" element={<Contact />} /> 
            </Routes>
         
          <Footer />
          </Suspense>
        </div>
      </Router>
    </div>
  );
}

export default App;
